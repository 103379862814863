var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('el-dialog',{attrs:{"title":"订单发货","visible":_vm.dialogSendVisible,"width":"30%","before-close":_vm.handleSendClose},on:{"update:visible":function($event){_vm.dialogSendVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"收件人","prop":"name","rules":[
          { required: true, message: '请填写收件人', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"type":"text","maxlength":"100","clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"收件人联系电话","prop":"phone","rules":[
          {
            required: true,
            message: '请填写收件人联系电话',
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"text","maxlength":"50","clearable":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"label":"收件地址","prop":"address","rules":[
          {
            required: true,
            message: '请填写收件人联系地址',
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"text","maxlength":"200","clearable":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleSendClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.send('form')}}},[_vm._v("确定修改")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }