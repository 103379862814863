<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>订单管理</el-breadcrumb-item>
          <el-breadcrumb-item>订单列表</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15 head">
      <el-row :gutter="40">
          <el-row :gutter="20">
            <el-col :span="12" class="orderStatus">
              订单状态: {{ orderStatusL[infoDetail.orderStatus] }}
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button-group>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  @click="dialogAddrVisible = true"
                >
                  修改收货人信息
                </el-button>
                <el-button
                  type="primary"
                  icon="el-icon-message"
                  @click="dialogSendVisible = true"
                >
                  修改物流信息
                </el-button>
                <el-button
                  type="primary"
                  icon="el-icon-circle-close"
                  @click="dialogCancelVisible = true"
                  >取消订单
                </el-button>
                <el-button type="primary" icon="el-icon-s-order" disabled
                  >备注订单</el-button
                >
              </el-button-group>
            </el-col>
          </el-row>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="24">
          <span class="grid-divider">基本信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">订单编号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.id"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">下单时间</div>
          <el-input
            placeholder=""
            v-model="infoDetail.createDate"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">会员账号（手机号）</div>
          <el-input
            placeholder=""
            v-model="infoDetail.account"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">支付方式</div>
          <el-row :span="24">
            <el-col style="padding: 0px" :span="4" class="right"> 商品: </el-col>
            <el-col style="padding: 0px" :span="6">
              <el-input
                placeholder=""
                v-model="infoDetail.exchangeBeanNum"
                :disabled="true"
              ></el-input>
            </el-col>
            <el-col style="padding: 0px" :span="4" class="right"> 运费： </el-col>
            <el-col style="padding: 0px" :span="6">
              <el-input
                placeholder=""
                v-model="infoDetail.freight"
                :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">订单来源</div>
          <el-input
            placeholder=""
            v-model="infoDetail.id"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">配送方式</div>
          <el-input placeholder="快递配送" :disabled="true"></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">快递公司</div>
          <el-input
            placeholder=""
            v-model="infoDetail.logisticsCompany"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">物流单号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.trackingNum"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="24">
          <span class="grid-divider">收货信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">收货人</div>
          <el-input
            placeholder=""
            v-model="infoDetail.consignee"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">手机号码</div>
          <el-input
            placeholder=""
            v-model="infoDetail.consigneeCellPhoneNumber"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span">
          <div class="sub-title">收货地址</div>
          <el-input
            placeholder=""
            v-model="infoDetail.deliveryAddress"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="24">
          <span class="grid-divider">商品信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="24">
          <el-table :data="infoDetail.orderItem">
            <el-table-column prop="id" label="商品 ID"></el-table-column>
            <el-table-column prop="goodsImage" label="商品 图片">
              <template slot-scope="scope">
                <el-image
                        style="width: 50px; height: 50px"
                        :src="scope.row.goodsImage"
                ><div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i></div
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称"></el-table-column>
            <el-table-column prop="saleProperties" label="规格"></el-table-column>
            <el-table-column prop="exchangeBeanNum" label="惠豆"></el-table-column>
            <el-table-column prop="purchaseQuantity" label="数量"></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <div class="admin_table_main_pagination">
            <span class="total">合计：</span>
            <span class="number">{{infoDetail.countExchangeBeanNum ? infoDetail.countExchangeBeanNum : 0}}惠豆</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="24">
          <span class="grid-divider">操作信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="24">
          <el-table :data="logs">
            <el-table-column prop="id" label="操作者"></el-table-column>
            <el-table-column prop="id" label="操作时间"></el-table-column>
            <el-table-column prop="id" label="订单状态"></el-table-column>
            <el-table-column prop="id" label="发货状态"></el-table-column>
            <el-table-column prop="id" label="备注"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <addr
      @dialog="getAddress"
      v-if="dialogAddrVisible"
      :data="infoDetail"
      @set-info-detail="setInfoDetail"
    ></addr>
    <cancel
      :row="infoDetail"
      @dialog="getDialog"
      v-if="dialogCancelVisible"
    ></cancel>
    <send :row="infoDetail" @dialog="getDialog" v-if="dialogSendVisible"></send>
  </div>
</template>
<script>
import addr from "@/components/admin/mall/order/address.vue";
import cancel from "@/components/admin/mall/order/cancel.vue";
import send from "@/components/admin/mall/order/send.vue";

export default {
  components: {
    cancel,
    send,
    addr,
  },
  props: {},
  data() {
    return {
      infoDetail: "",
      goods: [],
      logs: [],
      span: 6,
      dialogAddrVisible: false,
      dialogCancelVisible: false,
      dialogSendVisible: false,
      orderStatusL: {
        0: "待付豆",
        1: "已支付豆",
        2: "已支付运费",
        3: "取消订单",
        4: "待发货",
        5: "已发货，待收货",
        6: "已收货 , 订单已完成",
        8: "售后中（退货申请待审核）",
        9: "交易关闭（退货审核不通过）",
        10: "交易中（待寄送退货商品）",
        11: "售后中（退货商品待收货）",
        12: "售后中（退货待入库）",
        13: "售后中（退货已入库）",
        14: "交易关闭（完成退款）",
        141: "待付豆超时",
        142: "待付运费超时",
      },
    };
  },
  methods: {
    orderDetail() {
      this.$get(
        this.$api.orderDetail + "/" + this.$route.query.id,
        this.whale
      ).then((res) => {
        this.infoDetail = res.data;
      });
    },
    getAddress(val) {
      this.dialogAddrVisible = val;
    },
    getDialog(val) {
      this.dialogSendVisible = val;
      this.dialogCancelVisible = val;
    },
    setInfoDetail(val) {
      this.infoDetail = val;
    },
  },
  created() {
    this.orderDetail();
  },
};
</script>
<style lang="scss" scoped>
  .el-col{
    padding: 0 20px;
  }
.is-always-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.is-always-shadow .el-col {
  height: auto;
}

.el-card {
  background: #F2F6FC;
}

.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
.head .el-col{
  height: 10px !important;
}
.el-col {
  border-radius: 4px;
  height: 88px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.orderStatus {
  font-size: large;
  color: #409EFF;
  padding-left: 30px!important;
}

.right {
  text-align: center;
  line-height: 30px;
}

.admin_table_main_pagination {
  line-height: 40px;
  text-align: right;
}

.admin_table_main_pagination .total {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.admin_table_main_pagination .number {
  font-size: 18px;
  font-weight: bold;
  color: #ff0000;
}
</style>
