<template>
  <div class="app">
    <el-dialog
      title="订单发货"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="收件人"
          prop="name"
          :rules="[
            { required: true, message: '请填写收件人', trigger: 'blur' },
          ]"
        >
          <el-input
            type="text"
            v-model="form.name"
            maxlength="100"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收件人联系电话"
          prop="phone"
          :rules="[
            {
              required: true,
              message: '请填写收件人联系电话',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="text"
            v-model="form.phone"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收件地址"
          prop="address"
          :rules="[
            {
              required: true,
              message: '请填写收件人联系地址',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="text"
            v-model="form.address"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      formCancel: "",
      form: {
        name: "",
        address: "",
        phone: "",
      },
      dialogSendVisible: true,
      formSend: "",
      logisticsInfos: {},
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const postData = {
                consignee: this.form.name,
                consigneeCellPhoneNumber: this.form.phone,
                deliveryAddress: this.form.address,
                id: this.data.id,
                orderNo: this.data.orderNo,
              };
              this.post(this.$api.consigneesave, postData);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    sendDialog() {
      this.form.name = this.data.consignee;
      this.form.phone = this.data.consigneeCellPhoneNumber;
      this.form.address = this.data.deliveryAddress;
      this.dialogSendVisible = true;
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          const { name, phone, address } = this.form;
          const newInfoDetail = { ...this.data };
          newInfoDetail.consignee = name;
          newInfoDetail.consigneeCellPhoneNumber = phone;
          newInfoDetail.deliveryAddress = address;
          this.$emit("set-info-detail", newInfoDetail);
          // this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
  },
  created() {
    this.sendDialog();
  },
};
</script>
